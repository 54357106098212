/*
    Helper methods to format currency based on a locale (saved in localstorage)
*/

const currencyLocaleMap = {
    GBP: 'en-GB',
    EUR: 'en-GB',
    USD: 'en-US',
    AUD: 'en-AU',
    CHF: 'de-CH',
    JPY: 'ja-JA',
    RUB: 'ru-RU',
    SEK: 'sv-SE',
    DKK: 'da-DA',
    ZAR: 'en-ZA',
};

const currencyToLocale = function (currency_code) {
    if (typeof currency_code === 'string') {
        currency_code = currency_code.toUpperCase();
        let currency_index = Object.keys(currencyLocaleMap).indexOf(currency_code);
        if (currency_index > -1) {
            return Object.values(currencyLocaleMap)[currency_index];
        }
    }
    return false;
};

const currentLocale = function (currency_code = false) {
    // If setting the current locale verify that we have a matched locale for that currency code
    if (typeof currency_code === 'string' && Object.keys(currencyLocaleMap).includes(currency_code)) {
        let locale = currencyToLocale(currency_code);
        sessionStorage.setItem('current-locale', locale);
        return locale;
    }

    // Get current locale from the users session, if we can't match it to a currency or it doesn't exist default it to en-GB
    let locale = sessionStorage.getItem('current-locale');
    if (!locale || !Object.values(currencyLocaleMap).includes(locale)) {
        sessionStorage.setItem('current-locale', 'en-GB');
        locale = 'en-GB';
    }
    return locale;
};

const parseDecimal = function (value) {
    return Math.round(parseFloat(value * 100)) / 100;
};

import { useDataStore } from '../../eventbooks/stores/DataStore';
window.localise = function (value, places = 0, currency_symbol = false, target_currency = null, conversion_rate = 1) {
    // This is to address issues around toLocaleString and parseFloat's rounding
    value = parseDecimal(value) * conversion_rate;
    let locale = currentLocale();
    const store = useDataStore();
    const eventCurrencyCode =
        store.get('current_event.currency_code') != null ? store.get('current_event.currency_code') : null;
    const accountCurrencyCode = store.get('account.currency_code') != null ? store.get('account.currency_code') : null;

    try {
        // set min value as zero
        if (1 / value === -Infinity) {
            value = 0;
        }

        // If no currency symbol required, return without one.
        if (!currency_symbol) {
            return value.toLocaleString(locale, {
                minimumFractionDigits: places,
                maximumFractionDigits: places,
            });
        }
        // Get the index of the locale symbol from the currency map, below.
        let locale_index = Object.values(currencyLocaleMap).indexOf(locale, {
            minimumFractionDigits: places,
            maximumFractionDigits: places,
        });

        // use the event over the account, if it exists
        let returningCode = accountCurrencyCode && accountCurrencyCode !== '' ? accountCurrencyCode : '';
        returningCode = eventCurrencyCode && eventCurrencyCode !== '' ? eventCurrencyCode : returningCode;
        returningCode = returningCode !== '' ? returningCode : Object.keys(currencyLocaleMap)[locale_index];

        // if a valid value (base 0) use code to return with it
        if (locale_index > -1) {
            return value.toLocaleString(locale, {
                style: 'currency',
                currency: target_currency ? target_currency : returningCode,
                minimumFractionDigits: places,
                maximumFractionDigits: places,
            });
        }

        // If we've got this far it's bombed.
        console.error('[LocaleFormatting] Unable to identify locale currency (Locale: ' + locale + ')');
        console.error(ex);

        return value.toLocaleString(locale, {
            minimumFractionDigits: places,
            maximumFractionDigits: places,
        });
    } catch (ex) {
        console.error('[LocaleFormatting] Unable to format currency based on locale (Locale: ' + locale + ')');
        console.error(ex);
    }

    return value;
};

const currencySymbolMap = {
    GBP: '£',
    EUR: '€',
    USD: '$',
    AUD: '$',
    CHF: 'CHF',
    JPY: '¥',
    RUB: '₽',
    SEK: 'kr',
    DKK: 'kr',
    ZAR: 'R',
};

const currencyToSymbol = function (currency_code) {
    if (typeof currency_code === 'string') {
        currency_code = currency_code.toUpperCase();
        let currency_index = Object.keys(currencySymbolMap).indexOf(currency_code);
        if (currency_index > -1) {
            return Object.values(currencySymbolMap)[currency_index];
        }
    }
    return false;
};
window.eventCurrencySymbol = function () {
    return currencyToSymbol(eventCurrencyCode);
};
